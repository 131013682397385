import React from 'react'
import { Wrapper, Title } from './style'

const Intro = props => 
  <Wrapper {...props} >
    <Title>
      Hi, I'm Vardan Sawhney,
      an <span>interdisciplinary</span> programmer
      based in Toronto, Canada.
    </Title>
  </Wrapper>

export default Intro
